import React from "react";
import { Image } from "react-bootstrap";
import BikeRepair from "../images/bikerepair.png";

const NotFoundPage = () => (
  <div className="page-not-found">
    <div>
      <Image src={BikeRepair} fluid={true} />
      <div className="page-text">
        <h2>Requested page<span> Not Found!</span></h2>
        <a href="/">Go to Homepage</a>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
